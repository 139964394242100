<template>
  <div id="app" class="mx-auto my-0 py-20 flex flex-col justify-center items-center">
    <!-- Header and Search Bar -->
    <div class="flex flex-col w-full md:w-3/4 items-center mb-4 pl-8 pr-4 md:px-0">
      <div class="flex items-center w-full justify-between ">
        <!-- Header -->
        <h3 class="text-sm md:text-xl font-medium font-sans">CARD MONITORING STATUS</h3>
        <!-- Search Bar -->
       <div class="relative">
        <input type="text" v-model="searchQuery" class="placeholder-xs text-xs pl-4 font-serif rounded-md pr-2 md:pr-24 py-2 custom-shadow w-48 md:w-72" placeholder="Search Bank">
        <svg class="absolute left-40 md:left-64 top-1/2 transform -translate-y-1/2" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
          <circle cx="6.39138" cy="6.7628" r="5.66994" stroke="#253B95" stroke-width="1.12143" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.4788 13.8502L10.3958 10.7672" stroke="#253B95" stroke-width="1.12143" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
       </div>
      </div>

    <!-- Transaction List -->
    <div class="py-8 md:py-12 mt-8 px-6 md:px-12 w-full custom-shadow rounded-xl font-serif">
      <h2 class="text-md font-semibold mb-2">Success Rate</h2>

    <table class="md:w-full overflow-x-hidden table-fixed md:table-auto">
      <thead>
        <tr>
          <th class="font-light text-sm whitespace-nowrap text-left py-5 rounded-tl-lg w-[9.5rem] ml-4 "></th>

          <!-- Loop through cardTypes array -->
          <template v-for="cardType in formattedCardTypes">
            <th  :key="cardType" class="text-black font-medium text-xs md:whitespace-nowrap text-center md:ml-6 py-2 w-16 md:w-12">
              <span class="dark-box-button rounded-lg hover:drop-shadow-lg py-2 md:px-4 cursor-pointer">
                {{ cardType }}
              </span>
            </th>
          </template>
        </tr>
      </thead>
      <tbody v-if="filteredTransactions">
        <!-- Loop through bank transactions -->
        <tr v-for="transaction in filteredTransactions" :key="transaction.bankName">
          <!-- Display bank name -->
          <td class="font-medium text-sm md:whitespace-nowrap text-left py-3 ml-4 md:ml-0">
            {{ formatBankName(transaction.bankName) }}
          </td>
          <!-- Loop through card types for each bank -->
          <template v-for="cardType in cardTypes">
            <td
              :key="cardType + transaction.bankName">
                <div class="w-12 mx-auto text-center text-[0.6rem] font-black py-2 px-2 border rounded-lg"
                :class="{
                  'bg-red-500 border-red-900 text-red-900 '
                  : (transaction[`${cardType.charAt(0).toLowerCase() + cardType.slice(1)}SuccessRate`] < 1 
                  && transaction[`${cardType.charAt(0).toLowerCase() + cardType.slice(1)}SuccessRate`] > 0
                  || transaction[`${cardType.charAt(0).toLowerCase() + cardType.slice(1)}SuccessRate`] > 1
                  && transaction[`${cardType.charAt(0).toLowerCase() + cardType.slice(1)}SuccessRate`] < 40),

                  'bg-yellow-500 border-amber-900 text-amber-900 '
                  : transaction[`${cardType.charAt(0).toLowerCase() + cardType.slice(1)}SuccessRate`] >= 40 
                  && transaction[`${cardType.charAt(0).toLowerCase() + cardType.slice(1)}SuccessRate`] < 70,
                  
                  'bg-green-500 border-green-900 text-green-900 ': transaction[`${cardType.charAt(0).toLowerCase() + cardType.slice(1)}SuccessRate`] >= 70
                  || transaction[`${cardType.charAt(0).toLowerCase() + cardType.slice(1)}SuccessRate`] === 0
                }">
                 {{
                  transaction[`${cardType.charAt(0).toLowerCase() + cardType.slice(1)}SuccessRate`] === 0 ? 'N/A' :
                  transaction[`${cardType.charAt(0).toLowerCase() + cardType.slice(1)}SuccessRate`] < 1 ? '0%' :
                  Math.floor(transaction[`${cardType.charAt(0).toLowerCase() + cardType.slice(1)}SuccessRate`]) + '%'
                }}
                </div>
            </td>

          </template>
        </tr>
      </tbody>

    </table>

    </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'App',
  data() {
    return {
      searchQuery: '',
      allTransactions: null,
      cardTypes: ["MasterCard", "VisaCard", "VerveCard"],
      error: null
    }
  },
  async created() {
     const response = await this.$store.dispatch('FETCH_TRANSACTIONS');

     if (response.status) {
      this.allTransactions = response.data.result
            console.log("transactions:", this.allTransactions);
     } else {
      console.error('response error', response.data.status, response.data.message);
     }

     this.refreshPage();
  },
  computed: {
    bankNames() {
      return this.allTransactions.map(transaction => this.formatBankName(transaction.bankName));
    },
    formattedCardTypes() {
      return this.cardTypes.map(cardType => {
        // Format "MasterCard" as "Mastercard"
        if (cardType === "MasterCard") {
          return "Mastercard";
        } 
        // Format "VisaCard" and "VerveCard" by removing the last 4 characters
        else if (cardType.endsWith("Card")) {
          return cardType.slice(0, -4);
        }
        // Return unchanged if not matching any condition
        else {
          return cardType;
        }
      });
    },
    filteredTransactions() {
      if (!this.allTransactions) return [];
      const query = this.searchQuery.toLowerCase();
      return this.allTransactions.filter(transaction => transaction.bankName.toLowerCase().includes(query));
    },
    ...mapGetters(['GET_TRANSACTIONS', 'GET_ERROR'])
  },
  watch: {
    allTransactions: {
      handler() {
        // Trigger filtering whenever allTransactions changes
        this.$nextTick(() => {
          this.filteredTransactions;
        });
      },
      immediate: true // Run the handler immediately after created() is executed
    }
  },
  methods: {
    ...mapActions(['FETCH_TRANSACTIONS']),
    formatBankName(bankName) {
      // Check if bankName already ends with "Bank"
      if (bankName.endsWith("Bank")) {
        return bankName;
      } else {
        const words = bankName.split('_');
        const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        return capitalizedWords.join(' ') + ' Bank';
      }
    },
    refreshPage(interval = 30 * 60 * 1000) { // Default interval: 30 minutes
      function reloadPage() {
        window.location.reload();
      }

      setInterval(reloadPage, interval);
    }
  }
}
</script>

<style>
  .custom-shadow {
    border-radius: 5.607px;
    background: #FFF;
    box-shadow: 0px 0px 22.429px 0px rgba(22, 120, 176, 0.12);
  }
</style>