import axios from "axios"

const state = {
    transactions: null,
    error: null
  }
  
  const mutations = {
    SET_TRANSACTIONS(state, transactions) {
      state.transactions = transactions;
      state.error = null;
    },
    SET_ERROR(state, error) {
      state.error = error;
      state.transactions = null;
    }
  };
  

  const actions = {
    async FETCH_TRANSACTIONS({ commit }) {
      try {
        console.log('trying to fetch data');
        
        // Get the current date and time
        const currentDate = new Date();
        const currentDateTime = currentDate.toISOString();

        // Adjust currentDateTime to match the format needed (e.g., remove milliseconds)
        const adjustedDateTimeString = currentDateTime.slice(0, -5).replace('T', ' ');

        // Calculate the start time as current time minus 30 minutes
        const startTime = new Date(currentDate.getTime() - 30 * 60000); // Subtract 30 minutes
        const startTimeString = startTime.toISOString().slice(0, -5).replace('T', ' '); // Convert to formatted string

        console.log('startTimeString:', startTimeString);
        console.log('adjustedDateTimeString:', adjustedDateTimeString);

        const apiUrl = process.env.VUE_APP_API_URL_DOMAIN;
        console.log('URL', apiUrl);

        const httpUrl = `${apiUrl}/api/v1/transactions/bank/success-rate?startDate=${startTimeString}&endDate=${adjustedDateTimeString}`;
        // const httpUrl = 'https://transaction-monitoring-develop.tractionapps.co/api/v1/transactions/bank/success-rate?startDate=2024-05-07 14:00:00&endDate=2024-05-07 18:00:00'
        // const httpUrl = "https://transaction-monitoring.tractionapps.co/api/v1/transactions/bank/success-rate?startDate=2024-06-03 17:20:04&endDate=2024-06-03 17:50:04"
        console.log("Request URL:", httpUrl);

        const httpRes = await axios.get(httpUrl);
        if (httpRes.status === 200) {
          commit('SET_TRANSACTIONS', httpRes.data.result)
          console.log('RESULTS:', httpRes.data.result);
        } else {
            const error = new Error('Failed to fetch data with status code: ' + httpRes.status);    
            commit('SET_ERROR', error);      
          console.log('unable to fetch data');
        }

        return httpRes;
      } catch (error) {
        commit('SET_ERROR', error)
      }
    }
  }
  
  
  const getters = {
    GET_TRANSACTIONS: state => state.transactions,
    GET_ERROR: state => state.error
  }

  
  export default {
    state,
    mutations,
    actions,
    getters
  }
  